<template>
  <div class="apply">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">企业经营信息</div>
    <div class="header">
        <div class="step">
            <div class="icon">
                <img src="../../assets/img/applyicon1.png" alt="">
            </div>
            <div class="p">企业信贷需求信息</div>
        </div>
        <div class="step">
            <div class="icon">
                <img src="../../assets/img/applyicon2.png" alt="">
            </div>
            <div class="p">企业基本信息</div>
        </div>
        <div class="step">
            <div class="icon">
                <img src="../../assets/img/applyicon3.png" alt="">
            </div>
            <div class="p">企业注册登记信息</div>
        </div>
        <div class="step">
            <div class="icon activebg" style="padding-top: 1.5vw;">
                <img src="../../assets/img/applyicon4.png" alt="">
            </div>
            <div class="p active">企业经营信息</div>
        </div>
        <div class="step">
            <div class="icon">
                <img src="../../assets/img/applyicon5.png" alt="">
            </div>
            <div class="p">完成</div>
        </div>
    </div>
    <div class="card">
        <div class="catalog">
            <p>上年销售收入（万元）</p>
            <input type="number" v-model="requestData.salesRevenueLastYear" placeholder="输入上年收入">
        </div>
        <div class="catalog">
            <p>上年利润（万元）</p>
            <input type="number" v-model="requestData.profitLastYear" placeholder="输入上年利润">
        </div>
        <div class="catalog">
            <p>上年资产额（万元）</p>
            <input type="number" v-model="requestData.assetsLastYear" placeholder="输入上年资产额">
        </div>   
        <div class="catalog">
            <p>上年负债额（万元）</p>
            <input type="number" v-model="requestData.liabilitiesLastYear" placeholder="输入上年负债额">
        </div>
    </div>
    <div class="flex-between">
        <p class="title">上游主要客户</p>
        <button class="add flex" @click="addClient(1)"><img src="../../assets/img/add.png">添加</button>        
    </div>    
    <div class="card" v-for="(item, index) in requestData.upMainCustomerList" :key="index">
        <div class="catalog">
            <p>规模</p>
            <div class="select">
            <select v-model="item.scale">
                <option value="0">微型</option>
                <option value="1">小型</option>
                <option value="2">中型</option>
                <option value="3">大型</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>主要客户名称</p>
            <input type="text" v-model="item.majorClient" maxlength="60">
        </div>
        <div class="catalog">
            <p>国家或地市</p>
            <input type="text" v-model="item.addressAreaName" maxlength="60">
        </div>
        <div class="catalog">
            <p>采购额业务占比</p>
            <input type="text" v-model="item.purchaseAmtRatio" maxlength="60">
        </div>
        <div class="catalog">
            <p>合作年限</p>
            <input type="text" v-model="item.cooperYears">
        </div>
        <div class="del-btn"><button class="del flex" @click="delClient(1,index)"><img src="../../assets/img/del_y.png">删除</button> </div>
    </div>
    <div class="flex-between">
        <p class="title">下游主要客户</p>
        <button class="add flex" @click="addClient(0)"><img src="../../assets/img/add.png">添加</button>        
    </div>    
    <div class="card" v-for="(item, index) in requestData.downMainCustomerList" :key="index">
        <div class="catalog">
            <p>规模</p>
            <div class="select">
            <select v-model="item.scale">
                <option value="0">微型</option>
                <option value="1">小型</option>
                <option value="2">中型</option>
                <option value="3">大型</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>主要客户名称</p>
            <input type="text" v-model="item.majorClient" maxlength="60">
        </div>
        <div class="catalog">
            <p>国家或地市</p>
            <input type="text" v-model="item.addressAreaName" maxlength="60">
        </div>
        <div class="catalog">
            <p>采购额业务占比</p>
            <input type="text" v-model="item.purchaseAmtRatio" maxlength="60">
        </div>
        <div class="catalog">
            <p>合作年限</p>
            <input type="text" v-model="item.cooperYears">
        </div>
        <div class="del-btn"><button class="del flex" @click="delClient(0,index)"><img src="../../assets/img/del_y.png">删除</button> </div>
    </div>
    <div style="text-align: center;padding: 4vw 0;">
        <div class="next" @click="goBack">上一步</div>
        <div class="next" @click="goConfirm">提交</div>
    </div>
    <!-- 企业信用报告查询授权书 -->
    <van-popup round v-model:show="showProtocol">
        <div class="pop">
            <div class="pop-header">企业信用报告查询授权书</div>
            <div class="pop-content">
                统一社会信用代码/组织机构代码：{{requireInfo.enterRegisterInfo.uniscid}}<br/>
                被授权方：<br/>
                授权书签订日期：2021-11-02<br/><br/>
                一、授权形式与期限<br/>
                （一）授权方知悉并同意本授权书以数据电文形式订立。<br/>
（二）内容自授权方在线确认本授权书（即勾选“同意并接受”《企业信用报告查询授权书》并点击“确定”按钮）之日起生效，有效期持续至授权方在被授权方申请（参与）办理的所有授信类金融产品终止之日止或者授权方明确的授权结束之日止。
                <br/><br/>
                二、授权内容
<br/>
授权方同意并不可撤销的向被授权方郑重授权查询、使用、留档以下内容：
<br/>
（一）包括但不限于企业相关政务信息：
<br/>
1.企业基本信息：营业执照信息、股东信息、高管人员、对外投资（企业对外投资、法人对外投资、法人对外任职）、企业变更记录、企业联系方式。
<br/>
2.司法风险：开庭公告、法律诉讼、法院公告、被执行人、失信信息、犯罪信息记录、立案信息、检察信息、司法协助、法院相关信息。
<br/>
3.经营风险：经营异常、行政处罚、严重违法、抽查检查、股权出质、动产抵押、欠税公告、环保处罚、税收违法、简易注销、股权冻结、清算信息、其他违规行为、经营欠费风险。
<br/>
4.经营信息：税务评级、财务数据、招投标信息、资质证书、行政许可、进出口登记信息、企业纳税信息、企业资产信息、企业社保缴纳信息、企业公积金缴纳信息、政采中标信息、其他经营信息。
<br/>
5.知识产权：商标信息、专利信息、软件著作权、作品著作权、科技奖励、网站备案、电信许可。
<br/>
6.包括但不限于与授权方收支流水相关的企业基本信息、企业收支账户信息、企业账户收支流向信息。
<br/>
7.包括但不限于农户信用报告、农户信息评级等农户库信息。
               <br/><br/>
               三、其他事项<br/>
（一）本协议履行过程中发生的争议，双方应友好协商，协商不成时，任何一方均可向平台所在地有管辖权的人民法院起诉。<br/>
（二）授权企业在此声明已知悉并理解本授权委托书，以及因提供非公开信息及负面信息可能导致的任何不利后果。<br/>
（三）本授权书是授权方向被授权方做出的单方承诺，在授权期限内不可撤销。
                <br/>
            </div>
            <div class="pop-footer">
                <div class="next" @click="goConfirm">同意</div>
                <div class="next gray" @click="closePop">不同意</div>
            </div>
        </div>
    </van-popup>
    <!-- 申请确认 -->
    <van-popup round v-model:show="showConfirm">
        <div class="pop">
            <div class="pop-header">申请确认</div>
            <div class="pop-confirm">
                <div v-if="requireInfo.productList" class="content">
                    <p class="title">产品名称：</p>
                    <p v-for="item in requireInfo.productList" :key="item.productId">{{item.creditName}}(编号：{{item.productId}}),</p>

                </div>
                <div class="content">
                    <p class="title">申请企业：</p>
                    <p>{{requestData.enterName}}</p>
                </div>
                <div v-if="requireInfo.bankCodeList.length" class="content">
                    <p class="title">贷款银行：</p>
                    <p v-for="item in requireInfo.bankCodeList" :key="item" class="yellow">{{item.orgName ? item.orgName :item.ORG_NAME }}</p>
                </div>
                <div class="content">
                    <p class="title">贷款金额：</p>
                    <p class="yellow"><span>{{requestData.loanAmt}}</span>万元</p>
                </div>
            </div>
            <div class="pop-footer">
                <div class="next" @click="confirm">确认</div>
                <div class="next gray" @click="closePop">取消</div>
            </div>
        </div>
    </van-popup>
    <!-- 申请成功 -->
    <van-popup round v-model:show="showFinish" :close-on-click-overlay="false">
        <div class="pop">
            <div class="pop-header">申请成功</div>
            <div class="pop-finish">
                <img src="../../assets/img/sussess.png" alt="">
                <p>恭喜您，您已经完成申请！</p>
            </div>
            <div class="pop-footer">
                <router-link to="/loanRecord" replace class="next">查看进度</router-link>
                <router-link to="/loan" replace class="next back">返回</router-link>
            </div>
        </div>
    </van-popup>
  </div>
</template>
<script>
import { Toast } from 'vant';
import { ref,reactive,toRaw,toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { apiSaveFrontRequirementOK,apiSaveRequirementOK,extendJson } from '../../api/axios';
export default {
   setup() {
     
    let router = useRouter();
    let store = useStore();
    let requireInfo;
    const requestData = ref({
        salesRevenueLastYear: '',
        profitLastYear: '',
        assetsLastYear: '',
        liabilitiesLastYear: '',
        upMainCustomerList: [],
        downMainCustomerList: []
    });
    if(store.state.requireInfo) {
        requireInfo = store.state.requireInfo;
        requestData.value = extendJson(toRaw(requestData.value),requireInfo.enterBusinessInfo);
        requestData.value = extendJson(toRaw(requestData.value),store.state.saveFrontRequirement);
        console.log(requireInfo,777)
    }else {
        router.push({ name:'home',replace:true});
    };

    const showProtocol = ref(false);
    const submit= () => {
        // showProtocol.value = true;
    };
    const goBack=() => {
        const sum=extendJson(toRaw(store.state.saveFrontRequirement),toRaw(requestData.value));
        store.dispatch('setRequestData',sum);
        router.push({ name:'applyC',replace:true})
    };
    const addClient = (i) => {
      if(i){
          requestData.value.upMainCustomerList.push({scale: '0',majorClient: '',addressAreaName:'',purchaseAmtRatio: '',cooperYears: ''});
      }else{
          requestData.value.downMainCustomerList.push({scale: '0',majorClient: '',addressAreaName:'',purchaseAmtRatio: '',cooperYears: ''});
      }      
    };
    const delClient = (i,index) => {
      if(i){
          requestData.value.upMainCustomerList.splice(index, 1);
      }else{
          requestData.value.downMainCustomerList.splice(index, 1);
      }      
    };   
    const closePop = () => {
        // showProtocol.value = false;
        showConfirm.value = false;
    };
    const showConfirm= ref(false);
    const goConfirm = () => {

        // showProtocol.value = false;
        showConfirm.value = true;
    };
    const showFinish= ref(false);
    //最终 申请确认
    const confirm = () => {
        requestData.value.loanAmt = requestData.value.loanAmt.toString();
        requestData.value.loanPeriod = requestData.value.loanPeriod.toString();
        requestData.value.peopleCnt = requestData.value.peopleCnt.toString();
        requestData.value.rateLevel = requestData.value.rateLevel.toString();
        requestData.value.assetsLastYear = requestData.value.assetsLastYear.toString();
        requestData.value.liabilitiesLastYear = requestData.value.liabilitiesLastYear.toString();
        requestData.value.profitLastYear = requestData.value.profitLastYear.toString();
        requestData.value.salesRevenueLastYear = requestData.value.salesRevenueLastYear.toString();

        if(requestData.value.orientRequireFlag){
            console.log('flag')
            apiSaveRequirementOK(toRaw(requestData.value)).then(res => {                 
                if(res.code == '1'){
                    showConfirm.value = false;
                    showFinish.value = true;      
                }
                else {
                    Toast({
                        duration: 5000,
                        message: '申请失败'+res.msg,
                    });
                    router.push({ name:'loan',replace:true});
                }
            });
        }else {
            // console.log('noflag')
            requestData.value.bankCode = requestData.value.bankCode.toString();
            console.log(toRaw(requestData.value),'applyD确认提交的参数');
            apiSaveFrontRequirementOK(toRaw(requestData.value)).then(res => {                  
                if(res.code == '1'){
                    showConfirm.value = false;
                    showFinish.value = true;      
                }
                else {
                    Toast({
                        duration: 5000,
                        message: '申请失败'+res.msg,
                    });
                    router.push({ name:'loan',replace:true});
                }
            });
        } 

    };

    return {
        requestData,
        requireInfo,
        submit,
        goBack,
        addClient,
        delClient,
        showProtocol,        
        closePop,
        showConfirm,
        goConfirm,
        showFinish,
        confirm
        
    };
  },
  
}
</script>
<style lang="less" scoped>
.apply {
    padding-top: 12.5vw;
.header {
    margin: 4vw;
    background-color: #fff;
    border-radius: 1.5vw;
    padding: 4vw;
    display: flex;
    justify-content: space-between;
    .step {
        width: 13vw;
        .icon {
            width: 100%;
            height: 13vw;
            background-color: #cccccc;
            border-radius: 50%;
            text-align: center;
            line-height: 13vw;
            box-sizing: border-box;
            img {
                transform: scale(0.5);
            }
        }
        .p {
            color: #666666;
            font-size: 3.1vw;
            text-align: center;
            margin-top: 2vw;
        }
        .activebg {
            background-color: #ff9900;
        }
        .active {
            color: #ff9900;
        }
    }
}
    .flex-between {
        padding: 0 4vw;
        margin-bottom: 4vw;
        p.title {
        margin: 0;
        font-size: 5vw;
        font-weight: 600;
        }
        button.add {
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                border-radius: 4vw;
                border: 0;
                font-size: 4vw;
                line-height: 5vw;
                padding: 1vw 2vw;
                img {
                    width: 5vw;
                    height: auto;
                    margin-right: 1vw;
                }
            }
    }
    
    .card {
        padding: 2vw 4vw;
        .catalog {
            display: flex;
            align-items: center;
            p:first-child {
                width: 26vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
                .key {
                    color: #ff6600;
                }
            }
            p:last-child {
                width: 55vw;
                font-size: 4vw;
                padding-left: 4vw;
            }
            select,input,textarea {
                background-color: #f4f8fe;
                border: 0;
                margin-left: 4vw;
                width: 55vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
            }
          

        }
        .del-btn {
            display: flex;
            justify-content: flex-end;
            button.del {
                color: #ff9900;
                border-radius: 4vw;
                border: 1px solid #ff9900;
                font-size: 4vw;
                line-height: 4vw;
                padding: 1vw 2vw;
                background-color: #fff;
                img {
                    width: 4.5vw;
                    height: auto;
                    margin-right: 1vw;
                }
            }
        }
        
    }
    .next {
        display: inline-block;
        background: linear-gradient(to right, #ff9900, #ff6700);
        color: #fff;
        font-size: 4vw;
        padding: 1.5vw 0;
        border-radius: 5vw;
        margin-left: 4vw;
        width: 22vw;
    }
    .pop {
        padding: 4vw;
        width: 70vw;
        .pop-header {
            font-size: 5vw;
            font-weight: 600;
            text-align: center;
            margin-bottom: 4vw;
        }
        .pop-content {
            width: 100%;
            height: 60vh;
            font-size: 4vw;
            overflow-y: auto;
            line-height: 6vw;
            letter-spacing: 1px;
        }
        .pop-confirm {
            .content {
                padding: 3vw 2vw;
                border-bottom: 1px solid #f5f5f5;
                p{
                    line-height: 6vw;
                    font-size: 3.5vw;
                }
                p.title {
                    font-weight: bold;
                    font-size: 4vw;
                }
                p.yellow{
                    color: #ff9900;
                    span {
                        font-size: 5vw;
                    }
                }
            }
            
             
        }
        .pop-finish {
            text-align: center;
            img {
                width: 100%;
                height: auto;
            }
            p {
                font-size: 4.5vw;
                color: #666666;
                margin: 4vw 0;
            }
        }
        .pop-footer {
            text-align: center;padding: 4vw 0;
            .gray {
                background: linear-gradient(to right, #989898, #686868);
            }
            .back {
                background: none;
                border: 1px solid #ff9900;
                color: #ff9900;
                box-sizing: border-box;
            }
        }
    }
}
</style>
